import formsFromStore from '@/modules/forms/store/forms-form-store';
import formsListStore from '@/modules/forms/store/forms-list-store';

export default {
    namespaced: true,

    modules: {
        form: formsFromStore,
        list: formsListStore,
    },
};
