import { FormsService} from '@/modules/forms/forms-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import i18n from '@/shared/plugins/vue-i18n';

// action types
export const DO_CREATE = 'create';
export const DO_UPDATE = 'update';
export const DO_REMOVE = 'remove';
export const DO_FIND = 'find';
export const DO_CLEAR = 'clear';

// mutation types
export const UPDATE_SUCCESS = 'updateSuccess';
export const FIND_SUCCESS = 'findSuccess';
export const RESET = 'reset';

const state = {
    record: null,
};

const getters = {
    record: (state) => state.record,
};

const actions = {
    [DO_CREATE](context, values) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'forms/create', { root: true });
            FormsService.create(values)
                .then((data) => {
                    Message.success(i18n.t('GENERAL.CREATED_SUCCESS'));
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'forms/create', { root: true });
                });
        });
    },
    [DO_UPDATE](context, { id, values }) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'forms/update', { root: true });
            FormsService.update(id, values)
                .then((data) => {
                    Message.success(i18n.t('GENERAL.UPDATED_SUCCESS'));
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'forms/update', { root: true });
                });
        });
    },
    [DO_REMOVE](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'forms/remove', { root: true });
            FormsService.remove(id)
                .then(() => {
                    context.commit('forms/list/removeRecord', id, { root: true });
                    Message.success(i18n.t('GENERAL.REMOVE_SUCCESS'));
                    resolve();
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'forms/remove', { root: true });
                });
        });
    },
    [DO_FIND](context, id) {
        return new Promise((resolve, reject) => {
            context.commit('shared/activateLoading', 'forms/find', { root: true });
            FormsService.find(id)
                .then(({ data }) => {
                    context.commit(FIND_SUCCESS, data);
                    resolve(data);
                })
                .catch(( error ) => {
                    Errors.handle(error);
                    reject();
                })
                .finally(() => {
                    context.commit('shared/deactivateLoading', 'forms/find', { root: true });
                });
        });
    },
    [DO_CLEAR](context) {
        context.commit(RESET);
    },
};

const mutations = {
    [FIND_SUCCESS](state, payload) {
        state.record = payload;
    },
    [RESET](state) {
        state.record = null;
    }
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
};
